.custom-container1 {
    padding: 0;
  }
  
  .title1 {
    justify-content: center;
    padding: 10px 0;
  }
  
  .node-label {
    background-color: white;
    width: 130px;
    height: 100px;
    align-content: center;
    padding: 10px;
    border-radius: 50%;
    font-size: 18px;
    color: black;
  }
  
  .profile-img{
    width: 50px;
  }
  .binary-tree {
    padding: 0;
  }
  
  .tree-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: nowrap;
  }
  
  .tree-col {
    flex: 1;
    text-align: center;
    margin: 10px;
  }
  
  .tree-children {
    display: flex;
    justify-content: space-around;
  }
  
  .tree-child {
    margin: 5px;
    padding: 0 5px;
  }
  
  .tree-link {
    display: block;
    text-decoration: none;
    color: inherit;
  }
  
  .no-data {
    text-align: center;
    margin: 20px 0;
  }
  
  /* Media queries for responsive design */
  @media (max-width: 768px) {
    .binary-tree {
      transform: scale(0.8); /* Scale down the entire tree */
      transform-origin: top center; /* Maintain the center alignment */
    }
  
    .node-label {
      font-size: 14px; /* Adjust font size for readability */
      padding: 5px;
    }
  
    .tree-col {
      margin: 5px;
    }
  
    .tree-child {
      margin: 5px 0;
    }
  }
  
  @media (max-width: 480px) {
    .binary-tree {
      transform: scale(0.5); /* Further scale down for smaller screens */
    }
  
    .profile-img{
      width: 45px;
    }
    .node-label {
      font-size: 12px;
      padding: 10px;
      width: 80px;
      height: 60px;
      border-radius: 10px;
    }
    .font-change{
      font-size: 10px !important;
    }
    .tree-col {
      margin: 5px 0;
    }
  
    .tree-child {
      margin: 3px 0;
    }
  }
  
  .modal_tree {
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    z-index: 1000;
  }
  
  .modal_tree-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
    z-index: 999;
  }


  .tooltip1 {
    position: relative;
    
  }
  
  .tooltip1 .tooltiptext1 {
    visibility: hidden;
  width: 180px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  
  /* Position the tooltip */
  position: absolute;
  z-index: 1500;
  top: 100%;
  left: 50%;
  margin-left: -60px;
  }
  
  .tooltip1:hover .tooltiptext1 {
    visibility: visible;
  }
  
  