/* ImageSlider.css */
.slider-container {
    width: 100%;
    max-width: 600px;
    margin: auto;
  }
  
  .slider-image {
    width: 100%;
    height: auto;
    border-radius: 15px;
  }
  