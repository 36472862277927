@import url('https://fonts.googleapis.com/css2?family=Lobster&display=swap');

:root {
    --primary-color: #009ad7;
    --secondary-color: #c3e6ff;
    --primary-text-color: white;
    --secondary-text-color: gray;
    --border-color: #B0BEC5;
    --background-color: #fff;
    --highlight-color: #8fd1ff;
    --highlight-border-color: #48b2ff;
    --report-card-bg-color: #cfebff;
    --input-height: 50px;
    --button-height: 57px;
    --input-border-radius: 50px;
    --button-border-radius: 20px;
    --btn-primary-color:#33a7fa;
    --btn-secondary-color:#0095ff;
}

.header-section {
    position: fixed;
    top: 0px;
    width: 100%;
    background-color: var(--background-color);
    height: 100px;
    border-bottom-left-radius: 40px;  /* Bottom left corner */
    border-bottom-right-radius: 40px; /* Bottom right corner */
    text-align: center;
    align-content: center;
    z-index: 1000; /* Ensures header is above other content */
}

.body-content {
    padding: 0 18px;
    margin-top: 10px; /* Adjust for the fixed header height + margin */
    margin-bottom: 125px; /* Adjust for the fixed footer height */
}

.card-body {
    margin-bottom: 53px;
}

.card-view {
    display: flex;
    justify-content: space-around;
}

.card-one, .card-two {
    width: 50%;
    height: 116px;
    border-radius: 20px;
    background-color: var(--background-color);
    padding: 15px 10px;
    border: 1px solid var(--border-color);
}

.card-one-1, .card-two-1 {
  width: 100%;
  height: 116px;
  border-radius: 20px;
  background-color: var(--background-color);
  padding: 15px 10px;
  border: 1px solid var(--border-color);
}
.card-one {
    margin-right: 20px;
}
.card-text-1{
    font-size: 12px;
    font-weight: 600;
    color: var(--secondary-text-color);
}
.card-text-2{
    font-size: 10px;
    font-weight: 600;
    color: var(--secondary-text-color);
}
.card-text {
    font-size: 14px;
    font-weight: 600;
    color: var(--secondary-text-color);
}

.main-card-text{
    font-size: 14px;
    font-weight: 600;
    color: white;
}
.main-card-value{
    font-size: 30px;
    font-weight: 600;
    color: white;
    margin-top: 10px;
}
.card-value {
    font-size: 30px;
    font-weight: 600;
    color: black;
    margin-top: 10px;
}

.card-icon {
    margin-right: 5px;
}

.url-btn, .short-bg-btn, .analytics-btn {
    padding: 10px 15px;
    height: var(--button-height);
    text-align: center;
    align-content: center;
    border-radius: var(--button-border-radius);
    cursor: pointer;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 16px;
}

.url-btn {
    color: var(--secondary-text-color);
    border: 1px solid var(--border-color);
    font-size:12px
}

.short-bg-btn{
    background-color: var(--primary-color);
    color: var(--primary-text-color);
    border: none;
}
.short-btn{
    background: linear-gradient(142.56deg, var(--btn-primary-color) -1.68%, var(--btn-secondary-color) 62.12%);
    color: var(--primary-text-color);
    border: none;
    height: var(--input-height);
    border-radius: 15px;
}
.amount-short-btn{
    background: linear-gradient(142.56deg, var(--btn-primary-color) -1.68%, var(--btn-secondary-color) 62.12%);
    color: var(--primary-text-color);
    border: none;
    border-radius: 10px;
}

.analytics-btn {
    background-color: var(--secondary-color);
    color: var(--primary-color);
    border: none;
}

.footer-content {
    position: fixed;
    bottom: 30px;
    width: calc(100% - 116px); /* Adjust for margin */
    margin-left: 58px;
    margin-right: 58px;
    z-index: 1000; /* Ensures footer is above other content */
}

.footer-body {
    background-color: var(--background-color);
    box-shadow: 0px 4px 6px var(--secondary-color);
    padding: 8px 0;
    border-radius: 50px;
    display: flex;
    justify-content: space-around;
}

.active-tab {
    background: linear-gradient(180deg, var(--primary-color) 25.68%, var(--secondary-color) 80.12%);
    padding: 8px;
    border-radius: 50px;
    box-shadow: 0px 4px 6px var(--highlight-border-color);
}

.form-control-new {
    border-radius: var(--input-border-radius);
    width: 100%;
    height: var(--input-height);
    align-content: center;
    padding: 0 0 0 15px;
    border: 0.5px solid var(--border-color);
    background-color: var(--secondary-color);
}

.form-select-new {
    border-radius: var(--input-border-radius);
    width: 100%;
    height: var(--input-height);
    align-content: center;
    padding: 0 30px 0 15px;
    border: 0.5px solid var(--border-color);
    background-color: var(--secondary-color);
}

.amount-btn {
    background-color: var(--primary-color);
    color: var(--primary-text-color);
    border: 1px solid var(--border-color);
    border-radius: 6px;
}

.report-card {
    margin-bottom: 16px;
    border-radius: 20px;
    background-color: var(--report-card-bg-color);
    height: 80px;
    padding: 8px 10px;
}

.report-name {
    font-size: 16px;
    font-weight: 800;
}

.report-content {
    padding: 0.5rem;
}

.copy-btn {
    background-color: var(--highlight-color);
    color: var(--primary-text-color);
    border: 2px solid var(--highlight-border-color);
    padding: 10px 8px;
    border-radius: 15px;
}

.report-date {
    padding: 5px 8px;
    font-size: 10px;
    color: #0d97f9;
    border: 1px solid #0d97f9;
    border-radius: 50px;
}
.card-1-new{
  background: linear-gradient(142.56deg, var(--btn-primary-color) -1.68%, var(--btn-secondary-color) 62.12%);
  color: rgb(255, 255, 255);
  padding: 10px;
  margin-top: 10px;
  border-radius: 20px;
  width: 180px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.about-section {
    display: flex;
    flex-direction: column;
    padding: 20px; /* Added padding */
    background-color: var(--background-color);
    /* Added border radius for a softer look */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Added box shadow for depth */
  }
  
  .about-item {
    margin-bottom: 20px;
  }
  
  .about-title-head {
    font-size: 32px; /* Increased font size */
    font-weight: bold;
    color: black; /* Set text color to white for contrast */
    margin-bottom: 15px; /* Increased bottom margin */
  }
  
  .about-title {
    font-size: 24px;
    font-weight: bold;
    color: black; /* Set text color to white for contrast */
    margin-bottom: 10px;
  }
  
  .about-description {
    font-size: 16px;
    color: black; /* Set text color to a lighter shade for contrast */
    line-height: 1.6; /* Adjusted line height for better readability */
    padding-left: 20px; /* Added left padding */
  }
  .level-table-head{
    background-color: var(--primary-color);
    color: var(--primary-text-color);
    text-align: center;
  }
  .level-table-body{
    text-align: center;
  }

  .report_tab{
  
    background-color: transparent;
    padding: 10px;
    border-radius: 10px;
    margin:0 2px;
    border: 1px solid var(--primary-color);
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }
  .report_active{
    background-color: var(--btn-secondary-color);
    color: var(--primary-text-color);
    border-radius: 10px;
    border: 1px solid var(--primary-color);
  }

  .pagination a {
    color: #0a0a0a;
    background-color: var(--primary-color); /* Background color for dark mode */
    padding: 8px 12px;
    border-radius: 4px;
    text-decoration: none;
  }
  
  .pagination a:hover {
    background-color: var(--btn-secondary-color);
    color: #fff; /* Hover background color for dark mode */
  }
  
  .pagination .active a {
    background-color: var(--btn-secondary-color);
    color: #fff; /* Active page background color for dark mode */
  }
.greeting-container{
    font-size: 18px;
    color: var(--primary-color);
    margin-bottom: 20px;
}
.greeting{
    /* font-family: "Lobster", sans-serif; */
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    color: var(--primary-color);
}
.card-total-income {
   
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* height: 116px; */
    border-radius: 20px;
    background-color: var(--background-color);
    padding: 15px 10px;
    border: 1px solid var(--border-color);
}

.section-total-income {
    /* Optional: Adjustments for text alignment, padding, or margin */
    flex: 1;
    text-align: center;
}

.divider-total-income {
    width: 1px; /* Thickness of the dividing line */
    height: 100%; /* Full height of the container */
    background-color: gray; /* Adjust color as needed */
    margin: 0 10px; /* Optional: Adjust spacing around the divider */
}

.team-section {
    display: flex;
    flex-direction: column;
  }
  
  .team-toggle {
    background-color: transparent;
    border: none;
    cursor: pointer;
    width: 100%;
    text-align: left;
    padding: 0px 0;
    display: flex;
    align-items: center;
    outline: none;
  }
  
  .team-toggle h3 {
    margin: 0;
  }
  
  .team-toggle-icon {
    margin-left: auto;
    transition: transform 0.3s ease;
  }
  
  .team-toggle-icon.open {
    transform: rotate(180deg);
  }
  
  .sub-menu {
    list-style: none;
    margin: 0;
    padding: 8px 0px;
    padding-left: 20px;
    overflow: hidden;
    transition: max-height 0.6s ease;
  }
  
  .sub-menu li {
    padding: 8px 0;
  }
  
  .sub-menu li h4 {
    margin: 0;
  }
  .graph{
    margin: 10px 0;
    padding:30px;
    text-align: center;
  }
  .business-head{
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    color: var(--primary-color);
  }
  .pagination-container {
    display: flex;
    justify-content: center;
    margin: 20px 0;
    flex-wrap: wrap; /* Allows pagination buttons to wrap on small screens */
  }
  
  .pagination-button {
    background-color: #87CEEB; /* Sky Blue */
    border: 1px solid #4682B4; /* Darker Blue for borders */
    color: #fff;
    padding: 8px 12px; /* Adjust padding for better touch targets on mobile */
    margin: 0 5px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px; /* Slightly smaller font size for mobile */
    transition: background-color 0.3s;
  }
  
  .pagination-button:disabled {
    background-color: #B0E0E6; /* Light Sky Blue */
    cursor: not-allowed;
  }
  
  .pagination-button.active {
    background-color: #4682B4; /* Dark Blue */
    font-weight: bold;
  }
  
  .pagination-ellipsis {
    margin: 0 5px;
    font-size: 14px; /* Slightly smaller font size for mobile */
  }
  
  @media (max-width: 600px) {
    .pagination-button {
      font-size: 12px; /* Further adjust font size for very small screens */
      padding: 6px 10px; /* Adjust padding for smaller screens */
    }
  }
  