/* Tree.css */
.tree {
    display: flex;
    justify-content: center;
  }
  
  .tree-node-container {
    display: flex;
    justify-content: space-between;
    overflow-x:auto;
    height: 300px;
  }
  
  .tree-node-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
  }
  
  .tree-node {
    background: #fff;
    border: 1px solid #ccc;
    padding: 5px 10px;
    border-radius: 4px;
  }
  
  .tree-node:after {
    content: '';
    position: absolute;
    width: 2px;
    background: #ccc;
    top: 0;
    left: 50%;
    height: 20px;
  }
  
  .tree-children {
    margin-top: 20px;
    display: flex;
    justify-content: space-around;
    width: 100%;
    position: relative;
  }

/* tree.css */
.tooltip-container {
  position: relative;
  display: inline-block;
}

.tooltip-content {
  visibility: hidden;
  background-color: #555;
  color: #fff;
  text-align: left;
  border-radius: 5px;
  padding: 10px;
  position: absolute;
  z-index: 1;
  width: 150px;
  top: 100%;
  left: 50%;
  margin-left: -60px; /* Use half of the width (120/2 = 60), to center the tooltip */
  opacity: 0;
  transition: opacity 0.3s ease;
}

.tooltip-container:hover .tooltip-content {
  visibility: visible;
  opacity: 1;
}
